import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl, UntypedFormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {ACCEPTABLE_EMAIL_FORMATS, getLoginServices} from '@authentication/login-service';
import {AddPermissionComponent} from '@commons/add-permission/add-permission.component';
import {getNavItems} from '@commons/commons-nav/commons-nav-util';
import {
  SensitiveDataDialogComponent,
  SensitiveDialogData,
} from '@commons/sensitive-data-dialog/sensitive-data-dialog.component';
import {ParsedError} from '@form-controls/error-message/error-message.component';
import {ErrorSnackbarComponent} from '@form-controls/error-snackbar/error-snackbar.component';
import {FormContainer, loadFieldsets} from '@form-controls/form-container';
import {CommonsApiService} from '@services/commons-api/commons-api.service';
import {
  Keyword,
  ProjectCreate,
  ProjectPut,
  ProjectResponse,
  ProjectRoleID,
  ProjectUserResponse,
  ProjectUserRoleReference,
} from '@services/landing-service';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {UserService} from '@services/user-service/user.service';
import {ProjectRoleLabels} from '@shared/labels/project-role';
import {CommonsSearchProject, CommonsStateForm, getRoleTooltip, UserPermissionMap} from '@shared/types/commons-types';
import {Fieldset} from '@shared/types/fieldset';
import {FormField} from '@shared/types/form-field';
import {FormSelectOption} from '@shared/types/form-select-option';
import {Institution} from '@shared/types/institution';
import {NavItem} from '@shared/types/nav-item';
import {ErrorMatcher} from '@shared/validators/error-matcher';
import {FormType, getFieldValidators} from '@shared/validators/get-field-validators';
import {cleanUpEmailsList} from '@shared/validators/landing_service_email.validator';
import {makeProjectPutFromProjectResponse} from '@utilities/commons-project-util';
import {removeEmptyItems} from '@utilities/remove-empty-items';
import {scrollToRouteParam} from '@utilities/scroll-to-selector';
import {firstValueFrom, lastValueFrom} from 'rxjs';

type ProjectFieldName = keyof ProjectCreate;
type ProjectFormFields = {[key in ProjectFieldName]: FormField};
type ProjectUserRoleMap = {[key: string]: ProjectUserRoleReference};

@Component({
  selector: 'app-commons-project-create-edit',
  templateUrl: './commons-project-create-edit.component.html',
  styleUrls: ['./commons-project-create-edit.component.scss'],
})
export class CommonsProjectCreateEditComponent implements OnInit {
  errorMessage: string;
  errorMatcher = new ErrorMatcher();
  errorMessagePerm: string;
  fields: Partial<ProjectFormFields> = {};
  fieldsets: Fieldset[] = [];
  fg: UntypedFormGroup = new UntypedFormGroup({});
  formContainer: FormContainer;
  formStatus: 'form' | 'submitting' | 'complete' = 'form';
  showConfirmDelete = false;
  createNew = false;
  error: String;
  displayedUserPermissionColumns: string[] = ['email', 'role', 'edit', 'delete'];
  emailFormatsText = ACCEPTABLE_EMAIL_FORMATS;
  getRoleTooltip = getRoleTooltip;
  newProject: ProjectCreate | ProjectPut;
  projectSearchObject: CommonsSearchProject;
  projectResponse: ProjectResponse;
  userPermissions: ProjectUserResponse[];
  navItems: NavItem[];
  institutions: Institution[];
  publisherName: string;

  constructor(
    public cas: CommonsApiService,
    public ras: ResourceApiService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public changeDetectorRef: ChangeDetectorRef,
    public router: Router,
    public route: ActivatedRoute,
    public userService: UserService,
  ) {}

  projectId(): string {
    return this.route.snapshot.paramMap.get('project_id');
  }

  isLoaded(): boolean {
    return !!(this.userService.user && this.newProject && this.formContainer);
  }

  projectName(): string {
    return `Project: ${this.projectResponse?.name || 'New Project'}`;
  }

  ngOnInit() {
    this.tryLoad();
  }

  async tryLoad() {
    if (this.userService.user) {
      await this.loadInstitutions();
      await this.loadProject();
      this.loadNavItems();
      this.loadFields();
      this.formContainer = new FormContainer(this.fields, this.fg);
      this.loadForm();
      this.fieldsets = loadFieldsets(this.fields);
      this.loadPermissions();
      await scrollToRouteParam(this.route);
    } else {
      setTimeout(() => {
        this.tryLoad();
      }, 1000);
    }
  }

  loadFields() {
    this.fields = {
      name: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string>(this.getDefaultFieldValue('name')),
        required: true,
        placeholder: 'Project Full Title:',
        type: 'text',
      }),
      alternate_name: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string>(this.getDefaultFieldValue('alternate_name')),
        required: false,
        placeholder: 'Alternate Project Name(s):',
        helpText: 'Enter another name used to refer to this project.',
        type: 'text',
      }),
      users: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string[]>(this.getDefaultFieldValue('users')),
        required: true,
        placeholder: 'Project Lead / Principal Investigator(s) Email(s):',
        helpText: 'Enter a list of email addresses. ' + ACCEPTABLE_EMAIL_FORMATS,
        tooltip:
          'One or more people can be listed as the PI.  This is a role that might be meaningful for the ' +
          'project in terms of regulatory responsibilities, grant funds, etc.  However, from a permissions point ' +
          'of view, the PI is just another project owner.  It is possible they are the only project owner.',
        type: 'list',
      }),
      description: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string>(this.getDefaultFieldValue('description')),
        required: true,
        placeholder: 'Brief Description:',
        type: 'textarea',
      }),
      keywords: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string[]>(this.getDefaultFieldValue('keywords')),
        required: true,
        placeholder: 'Key Words:',
        helpText: 'Enter a list of words or phrases that people can use to search for this project.',
        type: 'list',
      }),
      source_organization: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string>(this.getDefaultFieldValue('source_organization')),
        required: true,
        placeholder: 'Source of Content / Host Institution',
        type: 'image_select',
        selectOptions: getLoginServices(this.institutions, false, this.userService.user).map(s => {
          return new FormSelectOption({
            id: s.name,
            name: s.name,
            color: s.color,
            image: s.image,
          });
        }),
        fieldsetId: 'publisher_prefs',
        fieldsetLabel: 'Home Institution',
        markdownBelow:
          'The default selection will be the institution associated with the project creator’s login. However, the Project may originate or be led by someone from another institution. Select "iTHRIV" if the project is led by multiple iTHRIV institutions. For all other projects being indexed in the Commons, select "Other Source / External".',
      }),
      partners: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string[]>(this.getDefaultFieldValue('partners')),
        required: false,
        placeholder: 'Partner Institutions:',
        helpText: 'Enter a list of partner institution names for this project.',
        type: 'list',
      }),
      funders: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string[]>(this.getDefaultFieldValue('funders')),
        required: false,
        placeholder: 'Funding Source(s):',
        helpText: 'Enter a list of funding sources for this project.',
        type: 'list',
      }),
      web_page_urls: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string[]>(this.getDefaultFieldValue('web_page_urls')),
        required: false,
        placeholder: 'Links to project webpages:',
        type: 'list',
      }),
    };
  }

  async loadProject() {
    this.createNew = !this.projectId();

    if (!this.createNew) {
      // Look up the Project in Elasticsearch to get its publisher name
      // (i.e., the Landing Service where this Project is stored)
      const projectSearchResults = await this.ras.getProjects(
        '',
        this.userService.user,
        false,
        {id: this.projectId()},
        true,
      );

      if (projectSearchResults.length > 0) {
        this.publisherName = projectSearchResults[0].publisher.name;
        this.projectSearchObject = projectSearchResults[0] as CommonsSearchProject;
      }

      // Get the Project from the appropriate Landing Service
      this.projectResponse = await lastValueFrom(
        this.cas.getProject(this.projectId(), this.userService.user, this.publisherName),
      );
    }

    this.newProject = makeProjectPutFromProjectResponse(this.userService.user, this.projectResponse);
    return this.projectResponse;
  }

  loadForm() {
    this.createNew = !this.projectResponse?.id;

    Object.entries(this.fields).forEach(([fieldName, field]) => {
      field.name = fieldName;
      if (field.formControl) {
        field.formControl.setValidators(getFieldValidators(field, FormType.PROJECT));
        field.formControl.patchValue(this.getDefaultFieldValue(fieldName));
        this.fg.addControl(field.name, field.formControl);
      }
    });

    if (!this.createNew) {
      this.validate();
    } else {
      // User hasn't touched the form yet, so clear any errors
      this.clearFormErrors();
    }
  }

  loadPermissions() {
    this.userPermissions = this.projectResponse?.project_users;
    this.formStatus = 'form';
    return this.userPermissions;
  }

  lookupRole(lookupKey: string) {
    return `PROJECT ${lookupKey.toUpperCase()}`;
  }

  async addPermission(): Promise<void> {
    // console.log(this.userPermissions);
    const dialogRef = this.dialog.open(AddPermissionComponent, {
      height: '400px',
      width: '600px',
      data: <UserPermissionMap>{
        userPermission: undefined,
        permissionsMap: ProjectRoleLabels,
        isDataset: false,
      },
    });

    const newUserPermission: ProjectUserRoleReference = await lastValueFrom(dialogRef.afterClosed());
    try {
      // Add the new permission to the project users list
      // console.log(newUserPermission);
      this.newProject.users.push(newUserPermission);

      // Save the changes
      await lastValueFrom(this.cas.updateProject(this.projectId(), this.newProject, this.projectResponse));
      await firstValueFrom(
        this.ras.syncProject(this.projectId(), this.userService.user, this.projectResponse.publisher.name),
      );
      await this.loadProject();
      await this.tryLoad();
      this.errorMessagePerm = '';
    } catch (error) {
      this.displayError(error);
      this.errorMessagePerm = error;
    }
  }

  async editPermission(userPermission: ProjectUserResponse): Promise<void> {
    const roleRef: ProjectUserRoleReference = {
      email: userPermission.user.email,
      role: userPermission.project_role_id as ProjectRoleID,
    };

    const dialogRef = this.dialog.open(AddPermissionComponent, {
      height: '400px',
      width: '600px',
      data: <UserPermissionMap>{
        userPermission: roleRef,
        permissionsMap: ProjectRoleLabels,
        isDataset: false,
        projectTeamMembers: this.projectResponse.project_users.map(ur => ({
          email: ur.user.email,
          role: ur.project_role_id as ProjectRoleID,
        })),
      },
    });

    const updatedUserPermission: ProjectUserRoleReference = await lastValueFrom(dialogRef.afterClosed());
    if (updatedUserPermission) {
      try {
        const userMap: ProjectUserRoleMap = Object.fromEntries(
          this.projectResponse.project_users.map(u => {
            return [
              u.user.email,
              {
                email: u.user.email,
                role: u.project_role_id as ProjectRoleID,
                is_project_pi: u.is_project_pi,
              },
            ];
          }),
        );

        // Update the user's permission in the project users list
        userMap[updatedUserPermission.email] = updatedUserPermission;

        // Save the changes
        await lastValueFrom(this.cas.patchProject(this.projectResponse, {users: Object.values(userMap)}));
        await firstValueFrom(
          this.ras.syncProject(this.projectId(), this.userService.user, this.projectResponse.publisher.name),
        );
        await this.tryLoad();
        this.errorMessagePerm = '';
      } catch (permissionError) {
        this.displayError(permissionError);
        this.errorMessagePerm = permissionError;
      }
    } else {
      await this.tryLoad();
    }
  }

  async deletePermission(userPermission: ProjectUserResponse) {
    try {
      const users: ProjectUserRoleReference[] = this.projectResponse.project_users
        .filter(u => u.user.email !== userPermission.user.email)
        .map(u => {
          return {
            email: u.user.email,
            role: u.project_role_id as ProjectRoleID,
            is_project_pi: u.is_project_pi,
          };
        });
      await lastValueFrom(this.cas.patchProject(this.projectResponse, {users}));
      await firstValueFrom(
        this.ras.syncProject(this.projectId(), this.userService.user, this.projectResponse.publisher.name),
      );
      await this.tryLoad();
      this.errorMessagePerm = '';
    } catch (error) {
      this.displayError(error);
      this.errorMessagePerm = error;
    }
  }

  async confirmSensitiveData() {
    const dialogRef = this.dialog.open(SensitiveDataDialogComponent, {
      height: '170px',
      width: '600px',
      data: <SensitiveDialogData>{
        project: this.newProject,
        confirm: false,
      },
    });
    const data: SensitiveDialogData = await lastValueFrom(dialogRef.afterClosed());
    return data.confirm;
  }

  validate() {
    Object.values(this.fields).forEach(field => {
      field?.formControl?.markAsTouched();
      field?.formControl?.updateValueAndValidity();
    });

    return this.fg.valid;
  }

  async submitProject($event) {
    $event.preventDefault();
    this.formStatus = 'form';
    this.validate(); // Validate must be called twice to display all errors
    if (this.validate()) {
      this.clearFormErrors();
      const confirm = await this.confirmSensitiveData();
      if (confirm) {
        await this.saveChanges();
      }
    } else {
      this.displayFormErrors();
    }
  }

  cancelProject() {
    if (this.projectId()) {
      this.router.navigate(['/private_commons', 'project', this.projectId()]);
    } else {
      this.router.navigate(['/home']);
    }
  }

  showNext(projectId: string) {
    this.router.navigate(['/private_commons', 'project', projectId]);
  }

  getFields() {
    return this.formContainer.getFields();
  }

  showForm() {
    this.formContainer.reset();
    this.formStatus = 'form';
  }

  copyFormValuesToProject() {
    // Copy string and string[] values from form to project as is
    (['name', 'description', 'alternate_name', 'keywords', 'web_page_urls'] as ProjectFieldName[]).forEach(
      (fieldName: string) => {
        const field = this.fields[fieldName];
        if (field) {
          this.newProject[fieldName] = field.formControl.value;
        } else {
          console.error('No field called', fieldName);
        }
      },
    );

    // Copy string[] values from form to OrganizationReference[] project properties
    (['funders', 'partners'] as ProjectFieldName[]).forEach((fieldName: string) => {
      this.newProject[fieldName] = this.fields[fieldName].formControl.value.map((f: string) => ({name: f}));
    });

    // Copy string value from form to project source_organization property in OrganizationReference format
    this.newProject.source_organization = {name: this.fields.source_organization.formControl.value};

    // Copy string[] values from form to project users property in ProjectUserRoleReference[] format
    this.newProject.users = this.combineUsersLists();

    //Add the requesting user into the users list, if it's not in there:
    if (!this.newProject.users.find(u => u.email === this.userService.user.email)) {
      this.newProject.users.push({
        email: this.userService.user.email,
        role: ProjectRoleID.OWNER,
        is_project_pi: false,
        is_project_contact: false,
      });
    }
  }

  async saveChanges() {
    this.copyFormValuesToProject();

    try {
      const isNew = this.createNew;
      let projectId = isNew ? undefined : this.projectId();

      if (isNew) {
        this.publisherName = this.userService.user.institution.name;
        const newProjectURL = await lastValueFrom(this.cas.createProject(this.newProject, this.publisherName));
        projectId = newProjectURL.split('/').pop();

        // Wait for the sync process to complete before redirecting to the new Dataset Details screen,
        // to ensure that the new dataset is available in Elasticsearch.
        this.formStatus = 'submitting';
        await this.ras.waitForSyncProject(projectId, this.userService.user, this.publisherName);
        await lastValueFrom(this.cas.getProject(projectId, this.userService.user, this.publisherName));
      } else {
        // No need to wait for the sync process to complete before redirecting to the Details screen for
        // an existing Project.
        this.formStatus = 'submitting';
        await lastValueFrom(this.cas.updateProject(projectId, this.newProject, this.projectResponse));
        await firstValueFrom(this.ras.syncProject(projectId, this.userService.user, this.publisherName));
      }

      this.errorMessage = '';

      this.showNext(projectId);
      this.formStatus = 'complete';
    } catch (e) {
      this.errorMessage = e || CommonsApiService.getErrorText(this.createNew ? 'create project' : 'update project');
      console.error(this.errorMessage);
      this.displayError(this.errorMessage);
      this.formStatus = 'form';
      this.changeDetectorRef.detectChanges();
    }
  }

  /**
   * Returns the combined, updated user list based on updated PI emails and the list of existing project users.
   * @private
   */
  combineUsersLists(): ProjectUserRoleReference[] {
    // The "users" form field is actually the list of PI emails.
    const newPIs: ProjectUserRoleMap = Object.fromEntries(
      cleanUpEmailsList(this.fields.users.formControl.value).map((email: string) => {
        return [email, {email: email, role: ProjectRoleID.OWNER, is_project_pi: true, is_project_contact: true}];
      }),
    );

    // The "project_users" field in the old project has the list of all project users, including PIs.
    const oldUsers: ProjectUserRoleMap = Object.fromEntries(
      (this.projectResponse?.project_users || [])?.map(u => {
        const isPI = !!newPIs[u.user.email];
        return [
          u.user.email,
          {
            email: u.user.email,
            role: (isPI ? ProjectRoleID.OWNER : u.project_role_id) as ProjectRoleID,
            is_project_pi: isPI,
            is_project_contact: isPI,
          },
        ];
      }),
    );

    // Combine and dedupe the lists of new and old users
    return Object.values({...oldUsers, ...newPIs});
  }

  toTypeUp(up: unknown) {
    return up as ProjectUserResponse;
  }

  getDefaultFieldValue(fieldName: string) {
    const field = this.fields[fieldName];
    const p = this.newProject;

    if (!field || !p) {
      return;
    }

    const defaultValueMap = {
      // Required fields
      description: p?.description || '',
      is_metadata_public: p?.is_metadata_public || false,
      keywords:
        removeEmptyItems(p?.keywords.map((k: string | Keyword) => (typeof k === 'string' ? k : k.text)) || []) || [],
      name: p?.name || '',
      source_organization: p?.source_organization?.name || '',
      users: p?.users.filter(us => us.is_project_pi).map(u => u.email) || [],

      // Optional fields
      alternate_name: p?.alternate_name || undefined,
      funders: p?.funders.map(f => f.name) || undefined,
      partners: p?.partners.map(p => p.name) || undefined,
      web_page_urls: p?.web_page_urls || undefined,
    };

    if (fieldName in defaultValueMap) {
      return defaultValueMap[fieldName];
    }
  }

  private loadNavItems() {
    const keys: CommonsStateForm[] = this.projectId()
      ? ['commons-private', 'commons-project-private', 'commons-project-create-edit']
      : ['commons-private', 'commons-project-create-edit'];
    this.navItems = getNavItems(keys, this.projectId());
  }

  private displayError(errorString?: string, parsedError?: ParsedError) {
    this.snackBar.openFromComponent(ErrorSnackbarComponent, {
      data: {errorString, parsedError, action: 'Ok'},
      duration: 5000,
      panelClass: 'snackbar-warning',
    });
    this.formStatus = 'form';
  }

  displaySelfError(action: string) {
    this.snackBar.openFromComponent(ErrorSnackbarComponent, {
      data: {
        errorString: `You aren't able to ${action} your own permissions. Please request another project owner to do that.`,
        action: 'Ok',
      },
      duration: 5000,
      panelClass: 'snackbar-warning',
    });
  }

  private async loadInstitutions() {
    this.institutions = await lastValueFrom(this.ras.getInstitutions());
  }

  private displayFormErrors() {
    const messages: ParsedError = {
      title: 'Please double-check the following fields:',
      errors: [],
    };

    Object.values(this.fields).forEach((field: FormField) => {
      const errors = field.formControl.errors;
      const label = field.placeholder;

      for (const errorName in errors) {
        if (errors.hasOwnProperty(errorName)) {
          switch (errorName) {
            case 'dateTimeRange':
              messages.errors.push({
                title: label,
                messages: [`Not a valid event start and end date/time.`],
              });
              break;
            case 'email':
              messages.errors.push({
                title: label,
                messages: [`Not a valid email address.`],
              });
              break;
            case 'commaDelimitedList':
              messages.errors.push({
                title: label,
                messages: [`The items in the list must be separated by commas.`],
              });
              break;
            case 'maxlength':
              messages.errors.push({
                title: label,
                messages: [`Not long enough.`],
              });
              break;
            case 'minlength':
              messages.errors.push({
                title: label,
                messages: [`Too short.`],
              });
              break;
            case 'required':
              messages.errors.push({
                title: label,
                messages: [`This field is required. It is currently empty.`],
              });
              break;
            case 'url':
              messages.errors.push({
                title: label,
                messages: [`Not a valid URL.`],
              });
              break;
            default:
              messages.errors.push({
                title: label,
                messages: [`Has an error.`],
              });
              break;
          }
        }
      }
    });

    this.displayError(undefined, messages);
  }

  clearFormErrors() {
    this.formContainer.getFields().forEach(f => f.formControl.setErrors(null));
  }
}
