import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {ParallelHasher} from 'ts-md5/dist/parallel_hasher';
import {FileAttachment} from '@shared/types/file-attachment';
import {FormField} from '@shared/types/form-field';
import {zoomTransition} from '@utilities/animations';
import {formatSize} from '@utilities/format-size';
import {firstValueFrom, lastValueFrom} from 'rxjs';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  animations: [zoomTransition()],
})
export class FileUploadComponent implements OnInit {
  @Input() field: FormField;
  filesDataSource: MatTableDataSource<FileAttachment>;
  displayedColumns: string[] = ['name', 'display_name', 'type', 'size', 'lastModifiedDate', 'actions'];
  dropZoneHover = false;
  progressValue = 0;
  displayProgressBar = false;
  progressUpdated = new EventEmitter<number>();
  formatSize = formatSize;

  constructor(private api: ResourceApiService) {
    this.progressUpdated.subscribe(value => (this.progressValue = value));
  }

  ngOnInit() {
    if (this.field && this.field.attachments && this.field.attachments.size > 0) {
      this.updateFileList();
    }

    this.field.formControl.valueChanges.subscribe(() => {
      this.updateFileList();
    });
  }

  dropped(files: NgxFileDropEntry[]) {
    this.dropZoneHover = false;

    files.forEach((droppedFile, i) => {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file(newFile => this.addFile(newFile));
      }
    });
  }

  fileOver($event) {
    this.dropZoneHover = true;
  }

  fileLeave($event) {
    this.dropZoneHover = false;
  }

  formatDate(d: Date | string | number): string {
    const dateObj = d instanceof Date ? d : new Date(d);

    return `
      ${dateObj.getFullYear()}/${dateObj.getMonth()}/${dateObj.getDay()}
      ${dateObj.getHours()}:${dateObj.getMinutes()}
    `;
  }

  truncate(s: string, maxLength = 20): string {
    if (s) {
      if (s.length > maxLength - 3) {
        return s.slice(0, maxLength) + '...';
      } else {
        return s;
      }
    } else {
      return '';
    }
  }

  fileType(file: FileAttachment): string {
    const s = file.mime_type || file.type || file.name || file.file_name;
    const nameArray = s.toLowerCase().split(file.mime_type || file.type ? '/' : '.');

    if (nameArray.length > 0) {
      return nameArray[nameArray.length - 1];
    } else {
      return 'unknown';
    }
  }

  fileIcon(file: FileAttachment): string {
    return `/assets/filetypes/${this.fileType(file)}.svg`;
  }

  addFile(attachment: FileAttachment) {
    const hasher = new ParallelHasher('/assets/js/md5_worker.js');
    hasher.hash(attachment).then(md5 => {
      attachment.md5 = md5;

      // Check for existing attachments
      let old: FileAttachment;
      this.field.attachments.forEach((f: FileAttachment) => {
        if (f.file_name === attachment.name) {
          old = f;
        }
      });

      if (old) {
        if (old.md5 !== md5) {
          // New version of existing attachment.
          // Copy all existing metadata to the new file.
          const keys = ['id', 'file_name', 'display_name', 'url', 'mime_type', 'resource_id'];
          keys.forEach(key => (attachment[key] = old[key]));
          this.field.attachments.set(md5, attachment);
          this.field.attachments.delete(old.md5);
        } else {
          // Same version of existing attachment. Do nothing.
        }
      } else {
        // New attachment.
        this.field.attachments.set(md5, attachment);
      }

      const apiFn = old ? 'updateFileAttachment' : 'addFileAttachment';

      // Upload changes to S3 immediately
      this.api[apiFn](attachment).subscribe(fa => {
        // Save the returned ID for later.
        this.editFileAttachment(fa, {id: fa.id});

        // Only upload the file blob if the bytes have changed.
        const sameBlob = old && old.md5 === attachment.md5;
        if (!sameBlob) {
          this.displayProgressBar = true;
          this.api.addFileAttachmentBlob(fa.id, attachment, this.progressUpdated).subscribe(f => {
            this.api.getFileAttachment(fa.id, md5).subscribe(updated => {
              this.editFileAttachment(fa, {url: updated.url});
              this.updateFileList();
            });
          });
        }
      });
      this.updateFileList();
    });
  }

  async removeFile($event, attachment: any) {
    $event.preventDefault();
    this.field.attachments.delete(attachment.md5);
    await firstValueFrom(this.api.deleteFileAttachment(attachment));
    this.updateFileList();
  }

  editFileAttachment(attachment: FileAttachment, options) {
    const file = this.field.attachments.get(attachment.md5);

    for (const key in options) {
      if (options.hasOwnProperty(key)) {
        file[key] = options[key];
      }
    }

    this.field.attachments.set(attachment.md5, file);
    this.updateFileList();
  }

  updateFileList() {
    this.displayProgressBar = false;
    this.filesDataSource = new MatTableDataSource<FileAttachment>(Array.from(this.field.attachments.values()));
  }

  updateDisplayName($event, attachment: FileAttachment) {
    attachment.display_name = $event.target.value;
    this.updateFileList();
  }

  getDisplayName(attachment: FileAttachment) {
    return attachment.display_name || attachment.file_name || '';
  }
}
