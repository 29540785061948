import {ChangeDetectorRef, Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatExpansionPanel} from '@angular/material/expansion';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {getCommonsObjectHomeInstitution, getUserLoginService, HomeInstitution} from '@authentication/login-service';
import {getNavItems} from '@commons/commons-nav/commons-nav-util';
import {HsdDownloadDialogComponent} from '@commons/hsd-download-dialog/hsd-download-dialog.component';
import {PublishDatasetDialogComponent} from '@commons/publish-dataset-dialog/publish-dataset-dialog.component';
import {ErrorSnackbarComponent} from '@form-controls/error-snackbar/error-snackbar.component';
import {CommonsApiService} from '@services/commons-api/commons-api.service';
import {
  DatasetFileVersionResponse,
  DatasetResponse,
  DatasetRoleID,
  DatasetUserResponse,
  FileVersionResponse,
  Keyword,
} from '@services/landing-service';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {UserService} from '@services/user-service/user.service';
import {DATASET_ROLES, DEID_LDS_HIPAA_OPTIONS, NA_HIPAA_OPTIONS} from '@shared/constants/constants';
import {DatasetAggregationLevel as DatasetAggregationLevelEnum} from '@shared/labels/dataset-aggregation-level';
import {IdentifierHipaa} from '@shared/labels/identifier-hipaa';
import {OtherSensitiveData} from '@shared/labels/other-sensitive-data';
import {SensitivityLevel} from '@shared/labels/sensitivity-level';
import {CommonsSearchDataset, DatasetUserPermissions, getRoleTooltip} from '@shared/types/commons-types';
import {FileAttachment} from '@shared/types/file-attachment';
import {ButtonToggleOptions} from '@shared/types/form-field';
import {NavItem} from '@shared/types/nav-item';
import {User} from '@shared/types/user';
import {isLocked} from '@utilities/commons-dataset-util';
import {formatSize} from '@utilities/format-size';
import {delay, firstValueFrom, lastValueFrom} from 'rxjs';
import {ParallelHasher} from 'ts-md5/dist/parallel_hasher';
import {VPNCheckService} from '@services/vpnCheckService/vpn-check.service';
import {FileUploadDialogComponent} from '@commons/file-upload-dialog/file-upload-dialog.component';
import {
  DeleteDocumentData,
  DeleteDocumentDialogComponent,
} from '@commons/delete-document-dialog/delete-document-dialog.component';
import {ViewportScroller} from '@angular/common';
import uuid from '@src/app/utilities/uuid';

interface PublishingCriterion {
  label: string;
  isMet: boolean;
}

interface PublishingOption {
  publishCase: number;
  title: string;
  criteria: PublishingCriterion[];
}

@Component({
  selector: 'app-private-dataset',
  templateUrl: './private-dataset.component.html',
  styleUrls: ['./private-dataset.component.scss'],
})
export class PrivateDatasetComponent implements OnInit {
  dataset: DatasetResponse;
  datasetWithPermissions: CommonsSearchDataset;
  userPermissions: DatasetUserPermissions;
  fileVersions: FileVersionResponse[];
  datasetFileUrl: string;
  datasetFileName: string;
  downloadInProgress: boolean;
  showConfirmDelete = false;
  error: string;
  errorMessage: string;
  publishCase?: number;
  displayedUserPermissionColumns: string[] = ['email', 'role'];
  displayedFileVersionColumns: string[] = [
    'file_version',
    'file_hipaa_warning',
    'creator',
    'create_date_time',
    'download',
    'delete',
  ];
  getRoleTooltip = getRoleTooltip;
  isLocked = isLocked;
  navItems: NavItem[];
  progressUpdated = new EventEmitter<number>();
  formatSize = formatSize;
  datasetDataToggleIsLoading: boolean = false;
  emptyResults = false;
  loadingDone = false;
  publishingOptions: PublishingOption[];
  @ViewChild('canPublishPanel') canPublishPanel: MatExpansionPanel;
  homeInstitution: HomeInstitution;
  userIsOnVPN: boolean;
  datasetScanComplete: boolean = false;
  dataToggleOptions: ButtonToggleOptions[] = [
    {
      label: 'DATA PRIVATE',
      tooltip: 'PRIVATE',
      icon: 'visibility_off',
      value: 'PRIVATE',
    },
    {
      label: 'DATA PUBLIC',
      tooltip: 'PUBLIC',
      icon: 'visibility',
      value: 'PUBLIC',
      onClick: async () => {
        if (!this.dataset.is_data_public) {
          if (this.canPublish()) {
            return await this.showDatasetDataPublicDialog();
          } else {
            !!this.canPublishPanel && this.canPublishPanel.open();
          }
        }
        return false;
      },
    },
  ];
  _ParallelHasher = ParallelHasher;
  publisherName: string;
  downloadedAmount: number;
  uploadedAmount: number;
  transferInProgress: boolean;
  transferSize: number;

  constructor(
    public cas: CommonsApiService,
    public ras: ResourceApiService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public router: Router,
    public userService: UserService,
    public snackBar: MatSnackBar,
    public changeDetectorRef: ChangeDetectorRef,
    public vpnCheckService: VPNCheckService,
    private viewportScroller: ViewportScroller,
  ) {}

  get datasetTitle(): string {
    return this.loadingDone && this.dataset ? `Dataset: ${this.dataset.name || ''}` : '';
  }

  get user(): User {
    return this.userService.getUser();
  }

  get projectId(): string {
    return this.route.snapshot.paramMap.get('project_id');
  }

  get datasetId(): string {
    return this.route.snapshot.paramMap.get('dataset_id');
  }

  get hasId(): boolean {
    return !!this.dataset?.id;
  }

  isEmptyArray(item: any) {
    return Array.isArray(item) && !item.length;
  }

  isContentLoadedAndOnVPN(): boolean {
    return this.loadingDone && this.vpnCheckService.userIsOnVPN;
  }

  private checkVPNStatus() {
    return new Promise<boolean>(async resolve => {
      await this.loadDatasetsIfVPNStatusHasChanged(this.vpnCheckService.userIsOnVPN);
      this.vpnCheckService.updated.subscribe(async isConnected => {
        await this.loadDatasetsIfVPNStatusHasChanged(isConnected);
        resolve(isConnected);
      });
    });
  }

  private async loadDatasetsIfVPNStatusHasChanged(isConnected) {
    const statusHasChanged = this.userIsOnVPN !== isConnected;
    this.userIsOnVPN = isConnected;

    if (isConnected && statusHasChanged) {
      await this.loadProject();
    }
    return isConnected;
  }

  ngOnInit() {
    this.checkVPNStatus();
    setTimeout(async () => {
      await this.tryLoad();
    }, 1000);
  }

  async tryLoad() {
    if (this.user && this.datasetId) {
      await this.loadProject();
      await this.loadDataset();
      await this.loadNavItems();
      await this.loadFileVersions();
      await this.getScanStatus();
    } else {
      setTimeout(() => {
        this.tryLoad();
      }, 1000);
    }
  }

  async downloadLatestDatasetFile() {
    if (this.blockDownload()) {
      return;
    }
    await this.downloadFile(this.getDownloadFileName(), this.user);
  }

  getDownloadURL(): string {
    if (this.blockDownload()) {
      return;
    }
    return this.datasetFileUrl;
  }

  getDownloadFileName() {
    if (this.blockDownload()) {
      return;
    }

    const fileVersion = this.cas.getLatestDatasetFileVersion(this.dataset);
    return fileVersion?.file_name;
  }

  blockDownload() {
    return !this.datasetWithPermissions?._can_download_data;
  }

  showDatasetPermissions() {
    this.router.navigate(['/private_commons', 'project', this.projectId, 'dataset', this.datasetId, 'edit'], {
      queryParams: {scrollTo: '#dataset_permissions'},
    });
  }

  async onFileComplete() {
    let old_file_version = this.dataset.dataset_file_versions.find(fv => fv.is_latest_and_not_deleted);
    await firstValueFrom(this.ras.syncDataset(this.datasetId, this.user, this.dataset.publisher.name));
    await this.waitForNewestFileVersion(old_file_version);
    await this.loadDataset();
    await this.loadFileVersions();
    this.datasetScanComplete = false;
    await this.getScanStatus();
  }

  async waitForNewestFileVersion(old_file_version: DatasetFileVersionResponse) {
    // Only check for file version updates if there are previous file entries:
    if (this.fileVersions.length > 0) {
      let resp = await lastValueFrom(this.cas.getDataset(this.datasetId, this.user, this.publisherName));
      let latest_file_version = resp.dataset_file_versions.find(fv => fv.is_latest_and_not_deleted);
      if (latest_file_version.file_version.id === old_file_version.file_version.id) {
        delay(1000);
        await this.waitForNewestFileVersion(old_file_version);
      } else {
        this.dataset = resp;
      }
    } else {
      await this.loadDataset();
    }
  }

  getUploadUrl() {
    return `${this.cas.getLandingServiceUrl(this.user, this.dataset)}/datasets/${this.dataset.id}/files`;
  }

  async loadPermissions(): Promise<DatasetUserPermissions> {
    // Load empty permissions by default.
    this.userPermissions = {
      all: [],
      team: [],
      customer: [],
    };

    if (this.datasetWithPermissions._can_upload_data) {
      try {
        this.dataset.dataset_users.forEach(perm => {
          this.userPermissions.all.push(perm);
          if (DatasetRoleID.CUSTOMER === (perm.dataset_role_id as DatasetRoleID)) {
            this.userPermissions.customer.push(perm);
          } else {
            this.userPermissions.team.push(perm);
          }
        });
      } catch (e) {
        console.error(e);
      }
    }

    return this.userPermissions;
  }

  async loadFileVersions(): Promise<FileVersionResponse[]> {
    try {
      this.fileVersions = this.dataset.dataset_file_versions
        .map(fv => fv.file_version)
        .filter(fv => fv.scheduled_for_deletion_on === null)
        .sort((a, b) => new Date(b.time_created).getTime() - new Date(a.time_created).getTime());
      return this.fileVersions;
    } catch (e) {
      console.error(e);
    }
    return [];
  }

  async getScanStatus(): Promise<boolean> {
    if (!this.datasetScanComplete) {
      const fileVersions = this.dataset.dataset_file_versions;

      if (fileVersions.length > 0) {
        const latestFileVersion = fileVersions.find(
          (fileVersion: any) => fileVersion.is_latest_and_not_deleted === true,
        );

        if (latestFileVersion && latestFileVersion.file_version.is_scan_complete) {
          this.datasetScanComplete = true;
        } else {
          this.checkForScanComplete();
        }
      } else {
        this.datasetScanComplete = true;
      }
    }
    return this.datasetScanComplete;
  }

  checkForScanComplete() {
    this.cas.checkFileScanComplete(this.user, this.dataset).subscribe(result => {
      if (result.error) {
        this.displayError(
          'Failed to get live file scan status. Periodically check back (refresh page) to get results.',
        );
      }
      this.datasetScanComplete = result.scanComplete;
      if (this.datasetScanComplete) {
        this.dataset = result.dataset;
        this.fileVersions = (this.dataset.dataset_file_versions || []) // Update dataset's fileVersions (includes HIPAA WARNINGS)
          .map(fv => fv.file_version)
          .filter(fv => fv.scheduled_for_deletion_on === null)
          .sort((a, b) => new Date(b.time_created).getTime() - new Date(a.time_created).getTime());
      }
    });
  }

  lookupRole(lookupKey: string) {
    return DATASET_ROLES[lookupKey];
  }

  async toggleMetadataPrivate() {
    try {
      await this.patchDataset('is_metadata_public', !this.dataset.is_metadata_public);
      await firstValueFrom(this.ras.syncDataset(this.datasetId, this.user, this.dataset.publisher.name));
    } catch (e) {
      this.errorMessage = e;
      console.error(this.errorMessage);
      this.displayError(this.errorMessage);
      this.changeDetectorRef.detectChanges();
    }
  }

  async toggleDataPrivate() {
    this.datasetDataToggleIsLoading = true;
    try {
      if (!this.dataset.is_data_public) {
        const latestFileVersion = this.cas.getLatestDatasetFileVersion(this.dataset);
        await this.patchDataset('is_data_public', true);

        // Sync dataset with elasticsearch before publishing file, and wait for celery success task
        await this.ras.waitForSyncDataset(this.datasetId, this.userService?.user, this.publisherName);

        // Send the landing service the DOI once dataset is synced
        await this.loadDataset();
        await this.patchDataset('doi', this.datasetWithPermissions.doi);

        this.transferInProgress = true;

        // Download the Dataset file from the Landing Service
        const token = localStorage.getItem('token');
        const headers = new Headers({
          Authorization: 'Bearer ' + token,
        });

        const downloadResponse = await window.fetch(`${this.datasetFileUrl}?download_stored_format=True`, {
          headers: headers,
        });
        if (!downloadResponse.ok) {
          throw new Error(await downloadResponse.json());
        }

        this.transferSize = latestFileVersion.file_size;
        if (downloadResponse.headers.has('Stored-File-Size')) {
          this.transferSize = Number.parseInt(downloadResponse.headers.get('Stored-File-Size'), 10);
        }
        const fa: FileAttachment = <FileAttachment>{size: this.transferSize};
        fa.dataset_id = this.dataset.id;
        fa.file_name = latestFileVersion.file_name;
        fa.mime_type = latestFileVersion.encoding_format;
        fa.md5 = latestFileVersion.file_checksum;
        const updated = await lastValueFrom(this.ras.addFileAttachment(fa));
        fa.id = updated.id;

        const downloadReader = downloadResponse.body.getReader();
        try {
          let uploadPromise = Promise.resolve();
          let downloadedBytes = 0;
          let uploadedBytes = 0;
          let uploadedChunks = 0;
          let chunksToUpload: Array<Uint8Array> = [];
          const transferId = uuid();
          const self = this;
          async function upload(chunks: Array<Uint8Array>) {
            try {
              const combinedSize = chunks.map(chunk => chunk.length).reduce((accum, current) => accum + current, 0);
              const megaChunk = new Uint8Array(combinedSize);
              let offset = 0;
              for (const chunk of chunks) {
                megaChunk.set(chunk, offset);
                offset += chunk.length;
              }
              // Upload the Dataset file to the public Dataset file endpoint
              const isLastChunk = uploadedBytes + megaChunk.length === self.transferSize;
              uploadedChunks += 1;
              await self.ras
                .addFileAttachmentChunk(
                  self.dataset.id,
                  transferId,
                  uploadedChunks,
                  uploadedChunks + (isLastChunk ? 0 : 1),
                  fa,
                  megaChunk,
                )
                .toPromise();
              uploadedBytes += megaChunk.length;
              self.uploadedAmount = (100 * uploadedBytes) / self.transferSize;
            } catch (err) {
              downloadReader.cancel();
              throw err;
            }
          }
          do {
            const chunk = await downloadReader.read();
            if (chunk.done) {
              break;
            }
            downloadedBytes += chunk.value.length;
            this.downloadedAmount = (100 * downloadedBytes) / this.transferSize;
            if (chunksToUpload.push(chunk.value) >= 256) {
              let u = chunksToUpload;
              uploadPromise = uploadPromise.then(function () {
                return upload(u);
              });
              chunksToUpload = [];
            }
          } while (true);
          // drain last set
          uploadPromise = uploadPromise.then(() => upload(chunksToUpload));
          await uploadPromise;
          this.snackBar.open('Dataset updated', 'Ok', {duration: 3000});
        } finally {
          this.transferInProgress = false;
          downloadReader.releaseLock();
        }
      } else {
        await this.patchDataset('is_data_public', false);
        await firstValueFrom(this.ras.syncDataset(this.datasetId, this.user, this.dataset.publisher.name));
        await this.loadDataset();
        this.snackBar.open('Dataset updated', 'Ok', {duration: 3000});
      }
    } catch (e) {
      this.errorMessage = e;
      console.error(this.errorMessage);
      this.displayError(this.errorMessage);
      this.changeDetectorRef.detectChanges();
    } finally {
      this.downloadInProgress = false;
      this.datasetDataToggleIsLoading = false;
      this.viewportScroller.scrollToAnchor(`fileTable`);
    }
  }

  async deleteDataset() {
    try {
      await lastValueFrom(this.cas.deleteDataset(this.dataset));
      await firstValueFrom(this.ras.syncOnDatasetDelete(this.datasetId, this.user, this.dataset.publisher.name));
      this.error = '';
      this.router.navigate(['/private_commons', 'project', this.projectId]);
    } catch (error) {
      if (error) {
        this.displayError(error);
        this.error = error;
      } else {
        this.errorMessage = CommonsApiService.getErrorText('delete dataset');
        this.displayError(this.errorMessage);
      }
    }
  }

  keywords() {
    return this.dataset.keywords.map((ks: string | Keyword) => {
      if (typeof ks === 'string') {
        return ks as string;
      } else {
        return (ks as Keyword).text;
      }
    });
  }

  async downloadFile(filename: string, user: User, fileId?: string) {
    if (!this.dataset.is_data_public) {
      // Open confirmation dialog first.
      const dialogRef = this.dialog.open(HsdDownloadDialogComponent, {
        height: '300px',
        width: '500px',
        data: {
          dataset: this.dataset,
          confirm: false,
          user: this.user,
        },
      });

      const data = await lastValueFrom(dialogRef.afterClosed());
      if (!data.confirm) {
        return;
      }
    }

    const targetFileUrl = await this.cas.getDatasetFilePresignedUrl(this.user, this.dataset, fileId);
    const targetLink = document.createElement('a');
    targetLink.href = targetFileUrl;
    targetLink.click();
  }

  async loadDataset() {
    this.loadingDone = false;
    this.dataset = await lastValueFrom(this.cas.getDataset(this.datasetId, this.user, this.publisherName));

    if (this.dataset == undefined) {
      this.loadingDone = true;
      this.emptyResults = true;
      this.changeDetectorRef.detectChanges();
      return;
    }

    /** TODO Fix Try/Catch for permissions */
    const resp = await lastValueFrom(this.ras.searchDatasets(this.user, {id: this.datasetId}));
    if (resp) {
      this.datasetWithPermissions = <CommonsSearchDataset>(<unknown>resp.hits.map(x => x._source)[0]);
    }
    this.emptyResults = false;
    this.datasetFileUrl = this.cas.getDatasetFileUrl(this.user, this.dataset);
    this.datasetFileName = this.getDownloadFileName();

    if (this.user && this.datasetWithPermissions) {
      await this.loadPermissions();
      this.updatePublishingOptions();
      // You must be a system admin or on the dataset team to view file versions
      if (this.user.role === 'Admin' || this.userPermissions?.team?.some(perm => perm.user.email === this.user.email)) {
        await this.loadFileVersions();
      }
    }
    this.homeInstitution = getCommonsObjectHomeInstitution(this.dataset, true);
    this.loadingDone = true;
    this.changeDetectorRef.detectChanges();
  }

  allowPublishing() {
    const loginService = getUserLoginService(this.user);
    return !!(loginService?.hasLandingService && loginService.allowPublishDatasets);
  }

  canPublish() {
    this.publishCase = 0;

    // OPTION 1
    // No hipaa markers, no other data, and public dataset metadata
    if (this.isPublishCaseMet(1)) {
      this.publishCase = 1;
    }
    // OPTION 2
    // No HSD hipaa markers, no other data, no hipaa warnings, and public dataset metadata
    // AND Data must be aggregated with cell size 11 or greater
    else if (this.isPublishCaseMet(2)) {
      this.publishCase = 2;
    }
    return !!this.publishCase && this.datasetScanComplete;
  }

  async patchDataset(patchField?: string, patchValue?: boolean | string) {
    await lastValueFrom(this.cas.patchDataset(this.dataset, {[patchField]: patchValue}));
    this.dataset = await lastValueFrom(this.cas.getDataset(this.datasetId, this.user, this.publisherName));
  }

  async showDatasetDataPublicDialog(): Promise<boolean> {
    this.cas.checkDatasetFileScanComplete(this.user, this.dataset);
    const dialogRef = this.dialog.open(PublishDatasetDialogComponent, {
      width: '50vw',
      data: {
        publishCase: this.publishCase,
        confirm: false,
        dataGranularityIsOther: this.dataset.data_aggregation.id === DatasetAggregationLevelEnum.OTHER,
        isScanComplete: this.datasetScanComplete,
      },
    });

    const data = await lastValueFrom(dialogRef.afterClosed());
    if (!data.confirm) {
      this.snackBar.open('Dataset data publishing cancelled', null, {
        duration: 3000,
        panelClass: 'snack-bar-warning',
      });
    }
    return data.confirm;
  }

  /**
   * Returns true if the dataset has no HSD (case 1) or is de-identified (case 2)
   * @param publishCase
   */
  isPublishCaseMet(publishCase: number) {
    if (!this.dataset || !this.publishingOptions) {
      return false;
    }
    const publishingOption = this.publishingOptions.find(p => p.publishCase === publishCase);
    return publishingOption.criteria.every(c => c.isMet);
  }

  updatePublishingOptions() {
    if (!this.dataset) {
      return;
    }
    const d = this.dataset;

    const naOptions = NA_HIPAA_OPTIONS.map(o => o.id);
    const deidLdsOptions = DEID_LDS_HIPAA_OPTIONS.map(o => o.id);
    const fileHipaaWarnings = this.cas.getLatestDatasetFileVersion(d)?.file_hipaa_warnings || [];

    this.publishingOptions = [
      {
        publishCase: 1,
        title: 'Option 1: No highly-sensitive data',
        criteria: [
          {
            label: 'HIPAA Identifiers include only `N/A, no patient data`',
            isMet: d.identifiers_hipaa.length === 1 && naOptions.includes(d.identifiers_hipaa[0].id),
          },
        ],
      },
      {
        publishCase: 2,
        title: 'Option 2: De-identified (De-ID) or Limited Data Set (LDS)',
        criteria: [
          {
            label: 'HIPAA Identifiers include only those marked as `De-ID` OR `LDS`',
            isMet:
              d.sensitivity_level.id !== SensitivityLevel.HSD &&
              d.identifiers_hipaa.every(i => deidLdsOptions.includes(i.id)),
          },
          {
            label: 'Data Aggregation is NOT `Row Level`',
            isMet: d.data_aggregation.id !== DatasetAggregationLevelEnum.ROW_LEVEL,
          },
          {
            label: 'No ACTIVE HIPAA alerts',
            isMet: fileHipaaWarnings.length === 0 || d.identifiers_hipaa[0].id === IdentifierHipaa.NA,
          },
        ],
      },
    ];

    this.publishingOptions.forEach(p => {
      p.criteria = p.criteria.concat([
        {
          label: 'Other Sensitive Data includes only `NONE`',
          isMet: d.other_sensitive_data.length == 1 && d.other_sensitive_data[0].id === OtherSensitiveData.NONE,
        },
        {
          label: 'Dataset Metadata is set to `PUBLIC`',
          isMet: d.is_metadata_public === true,
        },
        {
          label: 'At least one data file has been uploaded',
          isMet: d.dataset_file_versions.length > 0,
        },
      ]);
    });
  }

  private displayError(errorString?: string) {
    this.snackBar.openFromComponent(ErrorSnackbarComponent, {
      data: {errorString, action: 'Ok'},
      duration: 50000,
      panelClass: 'snackbar-warning',
    });
  }

  async loadNavItems() {
    this.navItems = getNavItems(
      ['commons-private', 'commons-project-private', 'commons-dataset-private'],
      this.projectId,
      this.datasetId,
    );
  }

  showScannerProgress(fileVersionDetail: unknown) {
    const file = fileVersionDetail as FileVersionResponse;
    return file.id === this.cas.getLatestDatasetFileVersion(this.dataset).id && !this.datasetScanComplete;
  }

  toTypedFV(fileVersionDetail: unknown) {
    return fileVersionDetail as FileVersionResponse;
  }

  hasFileHipaaWarnings() {
    return this.dataset.dataset_file_versions.filter(fv => fv.file_version.file_hipaa_warnings.length > 0).length > 0;
  }

  toTypedUP(userPermission: unknown) {
    return userPermission as DatasetUserResponse;
  }

  async loadProject() {
    const results = await lastValueFrom(this.ras.searchProjects(this.user, {id: this.projectId}));
    this.publisherName = results.hits[0]?._source?.publisher?.name;
  }

  showExternalDatasetButton() {
    return this.dataset && this.dataset.link_to_external_dataset && this.datasetWithPermissions._can_download_data;
  }

  async uploadFile() {
    const dialogRef = this.dialog.open(FileUploadDialogComponent, {
      height: '300px',
      width: '500px',
      data: {
        datasetFileUrl: this.getUploadUrl(),
        user: this.user,
        complete: false,
      },
    });

    const data = await lastValueFrom(dialogRef.afterClosed());
    if (data?.complete) {
      await this.onFileComplete();
    }
  }

  async confirmDelete(file_name: string, id: string) {
    const dialogRef = this.dialog.open(DeleteDocumentDialogComponent, {
      height: '200px',
      width: '600px',
      data: <DeleteDocumentData>{
        confirm: false,
        document_name: file_name,
      },
    });
    const data: DeleteDocumentData = await lastValueFrom(dialogRef.afterClosed());
    if (data.confirm) {
      await firstValueFrom(this.cas.deleteDatasetFile(this.user, this.dataset, id));
      await firstValueFrom(this.ras.syncDataset(this.datasetId, this.user, this.dataset.publisher.name));
      await this.loadDataset();
      await this.loadFileVersions();
    }
  }
}
