import {DatePipe} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {CommonsApiService} from '@services/commons-api/commons-api.service';
import {DatasetResponse, RedcapDatasetResponse, TemporalCoverageResponse} from '@services/landing-service';
import {UserService} from '@services/user-service/user.service';
import {CommonsSearchDataset, RightColField} from '@shared/types/commons-types';
import {removeEmptyItems} from '@utilities/remove-empty-items';

@Component({
  selector: 'app-dataset-details-card',
  templateUrl: './dataset-details-card.component.html',
  styleUrls: ['./dataset-details-card.component.scss'],
})
export class DatasetDetailsCardComponent implements OnInit {
  @Input() dataset: DatasetResponse;
  rightColFields: RightColField[];
  rightColLinkFields: RightColField[];

  constructor(
    private cas: CommonsApiService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.getRightColFields();
  }

  async getRightColFields(): Promise<RightColField[]> {
    const d = this.dataset;
    const genericFields: RightColField[] = [
      {label: 'Dataset Type', value: d.dataset_type},
      {label: 'HIPAA Identifier(s)', value: d.identifiers_hipaa.map(i => i.text)},
      {label: 'Source Organization', value: d.source_organization.name},
      {label: 'Other Sensitive Data', value: d.other_sensitive_data.map(o => (typeof o === 'string' ? o : o.text))},
      {label: 'Data Granulation', value: d.data_aggregation.id === 'not-selected' ? '' : d.data_aggregation.id},
      {label: 'Data Attribution', value: d.attribution},
      {label: 'Modified Date/Time', value: this.displayDateTime(d.time_updated)},
      {label: 'Variable Measured', value: d.variables_measured.map(v => (typeof v === 'string' ? v : v.text))},
      {label: 'License', value: d.license},
      {label: 'Spatial Coverage Address', value: this.stringifyObject(removeEmptyItems(d.spatial_coverage))},
      {label: 'Data Collection Period', value: d.temporal_coverage && this.displayDateRange(d.temporal_coverage)},
      {label: 'Study IRB Number', value: d.study_irb_number},
      {label: 'DOI', value: d.doi?.startsWith('doi:') ? `[${d.doi}](https://doi.org/${d.doi.substring(4)})` : d.doi},
      {label: 'Link to External Dataset', value: d.link_to_external_dataset},
      {label: 'REDCap Extract Status', value: this.parseExtractStatus()},
    ];
    this.rightColLinkFields = [
      {label: 'Link to Contract', value: await this._getProjectFileLink(d, 'contract')},
      {label: 'Link to Data Security Plan', value: await this._getProjectFileLink(d, 'data_security_plan')},
      {label: 'Link to IRB Approval Document', value: await this._getProjectFileLink(d, 'approved_irb')},
    ];
    /**
    if (this.dataset.dataset_type === DatasetType.DICOM) {
      genericFields[0].value = DatasetType.DICOM;
      this.rightColFields = [...genericFields, ...this.getDicomFields()];
    }
    if (this.dataset.dataset_type === DatasetType.REDCAP) {
      genericFields[0].value = DatasetType.REDCAP;
      this.rightColFields = [...genericFields, ...this.getRedcapFields()];
    }
     */

    this.rightColFields = this.filterEmptyValues(genericFields);
    this.rightColLinkFields = this.filterEmptyValues(this.rightColLinkFields);

    return this.rightColFields;
  }

  filterEmptyValues(fields) {
    return fields.filter(
      f => f.value !== '' && f.value !== undefined && f.value !== null && !this.isEmptyArray(f.value),
    );
  }

  displayDateTime(d: string) {
    return new DatePipe('en-US').transform(d, 'MM/d/y h:mm a');
  }

  displayDate(d: Date) {
    return new DatePipe('en-US').transform(d, 'MM/d/y');
  }

  displayDateRange(d: TemporalCoverageResponse) {
    if (
      d?.start_date &&
      d?.end_date &&
      d?.start_date != '0001-01-01T00:00:00Z' &&
      d?.end_date != '0001-01-01T00:00:00Z'
    ) {
      return this.displayDate(new Date(d.start_date)) + ' - ' + this.displayDate(new Date(d.end_date));
    }
  }

  isEmptyArray(item) {
    return Array.isArray(item) && !item.length;
  }

  /** TODO: By design, the redcap and dicom specific fields aren't displayed- Review this
  getDicomFields(): RightColField[] {
    const d = this.dataset as DICOMDatasetResponse;
    return [
      {label: 'Deidentified', value: d.dicom_metadata?.de_identified},
      {label: 'Bids Structure', value: d.dicom_metadata?.bids_structure},
      {label: 'Quality', value: d.dicom_metadata?.quality},
      {label: 'Study Date', value: d.dicom_metadata?.study_date},
      {label: 'Scanner Manufacturer Name', value: d.dicom_metadata?.scanner_manufacturer_name},
      {label: 'Scanner Model Name', value: d.dicom_metadata?.scanner_model_name},
      {label: 'Organ Name', value: d.dicom_metadata?.organ_name},
      {label: 'Field of View', value: d.dicom_metadata?.field_of_view},
      {label: 'Field Strength', value: d.dicom_metadata?.field_strength},
    ];
  }

  getRedcapFields(): RightColField[] {
    const d = this.dataset as RedcapDatasetResponse;
    return [
      {label: 'Project URL', value: d.redcap_metadata?.redcap_project_url},
      {label: 'Extract Data', value: d.redcap_metadata?.redcap_extract_data},
      // {label: 'Refresh Rate', value: d.redcap_metadata?.redcap_refresh_rate},
      {label: 'Report ID', value: d.redcap_metadata?.redcap_report_id},
      {label: 'Project Token', value: ''},
      {label: 'Project Title', value: d.redcap_metadata?.redcap_project_title},
      {label: 'Project PI', value: d.redcap_metadata?.redcap_project_pi},
    ];
  }
   */
  keywords(): string[] {
    return this.dataset.keywords.map(k => (typeof k === 'string' ? k : k.text));
  }

  private async _getProjectFileLink(d: CommonsSearchDataset | DatasetResponse, category: string) {
    const pdr = d.associated_projects.find(pdr => !!pdr[category]);

    if (!pdr) return;

    const landingServiceUrl = this.cas.getLandingServiceUrl(this.userService.user, d);
    return `${landingServiceUrl}/projects/${pdr.project.id}/files/${pdr[category].id}`;
  }

  private stringifyObject(obj: any) {
    if (obj) return Object.values(obj).join(', ');
    return '';
  }
  x;
  async openLink(url: string) {
    return;
    // TODO: Decide how these links should be opened (in browser or downloadable)
    // window.open(file, "_blank");
  }

  private parseExtractStatus() {
    let status = (this.dataset as RedcapDatasetResponse)?.redcap_metadata?.extract_status;
    return !!status
      ? JSON.parse(JSON.stringify((this.dataset as RedcapDatasetResponse)?.redcap_metadata?.extract_status))
      : undefined;
  }
}
